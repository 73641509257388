.projectCard {
  position: relative;
  height: 100%;
  list-style-type: none;
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
}


.imageContainer>img {
  width: 100%;
  justify-content: flex-end;
  border-radius: 5px;

}

.links {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.links>a>h2 {
  font-size: 3rem;
  margin: .2em;
  border-left: 4px solid rgb(7, 5, 28);
  border-color: #ed9b40;
  padding-left: .2em;
  color: black;
}

.links>a {
  color: #61c9a8;
  cursor: pointer;
}

.links>a>img {
  width: 3em;
  height: 3em;
  border-radius: 5px;
}

.imageContainer {
  display: inline-block;
  position: relative;
  height: 20em;
  width: 30em;
  border-radius: 5px;
  overflow: hidden;
}

.imageContainer > img {
  height: 20em;
}

.imageContainer:hover>p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  right: 0;
  background-color: rgba(40, 39, 39, 0.8);
  color: white;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.12rem;
  letter-spacing: 0.51px;
  line-height: 2rem;
  z-index: 3;
}

.imageContainer>p {
  display: none;
}

.imageTechContainer {
  display: flex;
}

.techUsed {
  order: -1;
  top: 0;
  right: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 320px;
  width: auto;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px 0 0 5px;
  flex-wrap: wrap;
  gap: 10px;

}

.techUsed img {
  width: 2em;
  height: 2em;
  margin: 0.5em;
}

.hackathonBanner {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 120%;
  height: auto;
  z-index: 1;
  background-color: rgba(53, 49, 115, 0.763);
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  transform: rotate(20deg);
}

@media screen and (max-width:1275px) {
  .projectCard {
    align-items: center;
    margin: 3em;
    border: 0;
    padding: 0;
    border-radius: 0;
  }

  .projectsContainer > * {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 0;
    padding: 0 0;
    border: 0 0;
  }

  .imageContainer {
    display: block;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 0;
    margin: 0;
    min-width: 350px;
  }

  .projectTextContainer {
    width: 80vw;
  }

  .mobileImgContainer {
    display: block;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 300px;
  }

  .links > a > h2 {
    font-size: 2.2rem;
    margin: .2em;
    border-left: 4px solid rgb(7, 5, 28);
    border-color: #ed9b40;
    padding-left: .2em;
    color: black;
  }

  .links > a {
    color: #61c9a8;
    cursor: pointer;
  }

  .links > a > img {
    width: 3em;
    height: 3em;
  }


  .imageTechContainer {
    flex-direction: column;
  }

  .techUsed {
    order: 1;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 320px;
    width: auto;
    padding: 0.5em;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 0 0 5px 5px;
    flex-wrap: wrap;
    gap: 10px;

  }

  .imageContainer > img {

    max-width: 700px;
    min-width: 350px;
  }


  .imageContainer:hover>p {
    font-size: .8em;
  }
}

@media screen and (max-width:480px) {
  .imageContainer{
    width: 300px;
  }

  .hackathonBanner {
    left: -20px;
    width: 400px
  }
}