.projectsContainer {
  min-width: 100vw;
  display: flex;
  font-size: var(--fs-900);
  justify-content: flex-end;
  margin-top: 80px;
}


.heroImg {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/harrisonBryce.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  position: fixed;
  z-index: 3;
  left: 0;
  clip-path: polygon(0 0, 27% 0, 56% 100%, 0 100%);
}

.projectTextContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: right;
  justify-content: center;
  margin: 0em 3rem 0 0;
  padding: 0 4em 0 0;
}

.projects {
  font-size: 3.3rem;
  margin-bottom: .5rem;
}

.aboutTextContainer > p {
  font-size: 1.22rem;
  letter-spacing: 0.51px;
  line-height: 2rem;
}

.projectDis {
  font-size: 1.22rem;
  letter-spacing: 0.51px;
  line-height: 2rem;
}

.pop {
  font-size: 1.8rem;
  color: #ed9b40;
}

.popTitle {
  color: #61c9a8;
}


@media screen and (max-width:1275px ) {
  .projectsContainer{
    flex-direction: column; 
    align-items: center;
  }
  
  .projectsContainer > * {
    text-align: left;
    margin: 0 0;
    padding: 0 0;
    border: 0 0;
    max-width: 900px;
    min-width: 350px;
  }
  
  .heroImg {
    display: none;

  }

  .projectTextContainer { 
    width: 90vw;
  }
  
  .mobileImgContainer {
    display: block;
  }

  .projects {
    font-size: 3em;
  }

  
}