.connectContainer {
  min-width: 100vw;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: var(--fs-900);
  justify-content: flex-end;
  margin-top: 80px;
}


.heroImg {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/hGrass.jpeg');
  background-repeat: no-repeat;
  background-size: 56%;
  position: fixed;
  z-index: 3;
  left: 0;
  clip-path: polygon(0 0, 27% 0, 56% 100%, 0 100%);

}

.connectTextContainer {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  align-items: flex-end;
  margin: 0 3rem;
  padding: 0 4em 9em 0;
}

.connect>h1 {
  font-size: 5rem;
  margin-bottom: .5rem;
}

.socialLinks {
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;

}

.socialLinks > * {
  /* border: 2px red solid; */
  display: flex;
  gap: 1em;
  font-size: 1.8em;
  color: black;
  margin: .5em;
  font-weight: 400;
  align-items: center;
}

.socialLinks > img {
  width: 1.5em;
  height: 1.5em;
}

.socialLinks > a {
  text-decoration: none;
}
.socialLinks > a:hover {
  color: #c97b0d;
}

/* .github, .linkedIn, .twitter, .resume {
  flex-direction: row;
} */

.resume:hover {
  color: #c97b0d; 
  cursor: pointer;

}

.socialLinks>a>img,
.resume>img {
  width: 2em;
  height: 2em;
  justify-content: space-around;
  align-items: center;
}

.resume>a>img {
  margin-right: 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 1.5em 0;
  width: 90%;
}

.formTop {
  display: flex;
  justify-content: space-between;

}

.formTop>* {
  width: 40%;
}


.topName,
.topEmail,
.message>label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.3em;
}

.formTop>div>input {
  width: 94%;
  height: 2em;
  border-radius: 5px;
}

.message {
  margin-top: 2em;
}

.message>textarea {
  border-radius: 5px;
  border-width: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 8em;
}

.message {
  display: flex;
  flex-direction: column;

}

.sendMessageBtn {
  width: 35%;
  align-items: center;

  margin: 1em 0 0 0;
  padding: 1.3em 3em;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #1afe98;
  background-image: linear-gradient(45deg, #1afe98 0%, #dbb279 51%, #c97b0d 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.sendMessageBtn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.sendMessageBtn:active {
  transform: scale(0.95);

}

input,
textarea {
  font-family: 'Montserrat', sans-serif;
  padding-left: .6em;

}

textarea {
  padding-top: .5em;
}

.popTitle {
  color: #61c9a8;
}

@media screen and (max-width:1275px) {
  .connectContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-height: 100vh;
    padding: 2em 0;
  }

  .connectContainer>* {
    text-align: center;
    margin: 0 0;
    padding: 0 0;
    border: 0 0;
  }

  .heroImg {
    display: none;
  }

  .connectTextContainer {
    width: 80vw;
    max-height: 100vh;
    display: flex;
    align-items: center;

  }

  .connectTextContainer>* {
    justify-content: center;

  }

  .mobileImgContainer {
    display: block;

  }

  .sendMessageBtn>* {
    padding: 1em em;
  }

  .connect>h1 {
    font-size: 3em;
  }

  .socialLinks {
    margin-top: 3em;
  }
}