html,
body,
#root,
main {
  margin: 0;
  height: 100%;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  background-color: #6a51300f;
}

