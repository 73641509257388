.stackContainer {
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  gap: 25px;
  max-width: 80%;
  justify-content: center;
  position: relative;
  /* gap: 20px; */
  padding: 0 1em 0 1em;
}

.stackContainer::before { 
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw; 
  height: 100%;
  background-color: #a09e9ecf; 
  z-index: -1;
}

.frontTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontStack {
  /* color: red; */
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  /* justify-content: space-around; */
  width: 70%;
  padding: 0;
  width: 6em;
  margin-bottom: 0;
  /* margin-right: 1em; */
}

.name {
  align-items: center;
  font-size: 1em;
}

.tech {
  scale: 80%;
}

.tech > img {
  height: 96px;
  width: auto;

}