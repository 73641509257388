.aboutContainer {
  min-width: 100vw;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: var(--fs-900);
  justify-content: flex-end;
  margin-top: 80px;
}


.heroImg {
  /* left: 0; */
  width: 100vw;
  height: 100vh;
  /* background-image: url('../../assets/about2.jpeg'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: left; */
  object-fit: cover;
  object-position: 10%;
  position: fixed;
  z-index: 3;
  clip-path: polygon(0 0, 27% 0, 56% 100%, 0 100%);
}

.pop {
  font-size: 1.8rem;
  color: #ed9b40;
}

.popTitle {
  color: #61c9a8;
}

.aboutTextContainer {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: right;
  justify-content: center;
  margin: 0 3rem;
  padding: 0 4em 9em 0;
  right: 0;
  /* z-index: 3; */
}

.about {
  font-size: 5rem;
  margin-bottom: .5rem;
}

.aboutTextContainer > p {
  font-size: 1.22rem;
  letter-spacing: 0.51px;
  line-height: 2rem;
}

@media screen and (max-width:1275px ) {
  .aboutContainer{
    flex-direction: column; 
    align-items: center;
    /* margin-top: 80px; */
    /* border: red 2px solid; */
    
  }
  
  .aboutContainer > * {
    text-align: center;
    margin: 0 0;
    padding: 0 0;
    border: 0 0;
  }
  
  .heroImg {
    display: none;

  }

  .aboutTextContainer { 
    width: 80vw;
    text-align: left;
  }
  
  .mobileImgContainer {
    display: block;
  }

  .about {
    font-size: 3em;
  }
  
}
