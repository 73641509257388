.projectListBody {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style-type: none;
  height: 100%;
  padding-right: .7rem;
}

.projectListBody > * {
  height: 100%;
}

@media screen and (max-width:1275px ) {
  .projectListBody{
    flex-direction: column; 
    align-items: center;
    /* background-color: red; */
    justify-content: center;
    padding: 0;
    /* width: 80vw; */
    /* max-width: 500px; */
    /* max-height: 500px; */
    /* margin-bottom: 1em; */
  }

  .projectListBody > li {
    /* background-color: black; */
    margin: 0;
  }
  
  .projectListBody > * {
    text-align: center;
    margin: 3em 0;
    padding: 0 0;
    border: 0 0;
    width: 80vw;
    justify-content: center;
    align-items: center;
  }

  
  .mobileImgContainer {
    display: block;
  }
  
  
}