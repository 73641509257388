  /* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap'); */


  .navigationBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    right: 0;
    z-index: 5;
    height: 80px;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
  }

  .navigationBar a {
    text-decoration: none;
    font-weight: 500;
    font-size: 22px;
    color: #D7B27B;
  }

  .navigationBar a:hover {
    cursor: pointer;
    border-bottom: #61c9a8 1px solid;
  }

  #logo:hover {
    border-bottom: none;
  }

  #logo {
    height: auto;
    width: auto;
  }

  .pageLinks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #D7B27B;
  }

  .pageLinks>* {
    margin: 0 1em;
    font-weight: 500;
    font-size: 22px;
    color: #D7B27B;
  }

  #logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10px;
  }

  #logo>* {
    height: 50px;
    margin: 0 2em;
  }


  .hamburger {
    color: none;
    height: 100%;
    width: 50px;
    height: 50px;
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 30px;
    z-index: 99;
  }

  .hamburger span {
    width: 100%;
    border: 3px solid black;
    border-radius: 20px;
  }

  .closeX span {
    margin-left: 10px;
    z-index: 99;
  }

  .closeX span:first-child{
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    border: 3px solid red;
  }

  .mobilePageLinks {
    height: 100%;
    background: lightsalmon;
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    display: none;
    flex-direction: column;
    color: black;
    align-items: flex-start;
    gap: 5em;
    padding-top: 100px;
    transition: all 0.5s ease;
    align-items: center;
    font-size: 1.3rem;
  }
  
  .mobilePageLinks > a {
    font-weight: 600;
    color: black;
    font-size: 1.4em;
  }

  #logoMobile {
    display: none;
    justify-content: flex-end;
    align-items: center;
    height: 10px;
  }

  #logoMobile > * {
    height: 50px;
    margin: 0 2em;
    border-bottom: none;
  }
  

  @media only screen and (max-width: 1275px) {

    .navigationBar {
      justify-content: space-between;
      width: 100vw;
      margin: 0;
      padding: 0;
      border: 0;
    }
    .hamburger {
      display: flex;
    }

    #logoMobile {
      display: flex;
      display: flex;
      border: none;
    }

    .pageLinks {
      display: none;
    }

    .mobilePageLinks {
      display: flex;
    }
    .pageLinks > * {
      color: black !important;
      font-size: 2rem !important;
    }
    
    #logo {
      order: -1;
    }

    
  }