.landingContainer {
  min-width: 100vw;
  display: flex;
  font-size: var(--fs-900);
  justify-content: flex-end;
  margin-top: 80px;
}


.landing {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/hero-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: fixed;
  z-index: 3;
  clip-path: polygon(0 0, 27% 0, 56% 100%, 0 100%);


}

.sand {
  width: 100vh;
  height: 100vh;
  background-size: cover;
  background-image: url('../../assets/hero-image-sand.png');
  position: fixed;
  z-index: 4;
  height: 100vh;
}

.animateDesktopSandClass {
  animation: sandSlideIn 4560ms ease-out 00ms 1 normal forwards;
  -webkit-animation: sandSlideIn 4560ms ease-out 00ms 1 normal forwards;
  -moz-animation: sandSlideIn 4560ms ease-out 00ms 1 normal forwards;
}

.meImg {
  width: 100vh;
  height: 100vh;
  background-size: cover;
  position: fixed;
  background-image: url('../../assets/hero-harrison.png');
  z-index: 5;
  cursor: pointer;
}

.animateDesktopMeClass {
  animation: slideIn 1500ms ease-out 00ms 1 normal forwards;
  -webkit-animation: slideIn 1500ms ease-out 00ms 1 normal forwards;
  -moz-animation: slideIn 1500ms ease-out 00ms 1 normal forwards;

}

.heroImgContainer {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: fixed;
  z-index: 2;
  left: 0;
  clip-path: polygon(0 0, 27% 0, 56% 100%, 0 100%);
}

.heroContentContainer {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 70vh;
  text-align: right;
  margin: 7rem 3rem 0 3rem;
  padding: 1rem 4em 3em 0;
  font-size: .8rem;
  shape-outside: polygon(0 0, 100% 0, 100% 100%, 57% 100%);
  line-height: 2.5rem;
}

.heroContentContainer>h1 {
  font-weight: 500;

}

.heroContentContainer>h1:nth-child(2) {
  margin-top: 0;
}

.name {
  font-size: 3.5rem;
  color: #61c9a8;
  line-height: 4rem;
  margin: .7em 0;
  margin-bottom: 0;

}

.pop {
  color: #ed9b40;

}

.subNameSpan {
  font-size: 1.8rem;
  border-bottom: 4px solid #f4eabe;
  ;
  display: none;
}

.subName {
  display: flex;
  width: 94.5%;
  justify-content: space-between;
}

.subName>* {
  text-decoration: none;
  list-style-type: none;
  letter-spacing: 0.51px;
  font-size: 2rem;
  font-weight: 500;
}

.subName>li:nth-child(1) {
  border-bottom: #ed9b40 solid 5px;
  border-radius: 5px;
}

.subName>li:nth-child(2) {
  border-bottom: #f4eabe solid 5px;
  border-radius: 5px;
}

.subName>li:nth-child(3) {
  border-bottom: #61c9a8 solid 5px;
  border-radius: 5px;
}

button {
  margin: 1em;
  padding: 1em 3em;
  border: 3px solid #ed9b40;
  border-radius: 25px;
  font-weight: 600;
  background-color: whitesmoke;
  /* margin-bottom: 6em; */
}

button:hover {
  cursor: pointer;
  color: whitesmoke;
  background-color: #61c9a8;
  transition: all;
}

@keyframes slideIn {
  from {
    transform: translate3d(-100%, -80%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sandSlideIn {
  0% {
    width: 0%;
  }

  20% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.mobileImg {
  position: relative;
  width: 100vw;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

@media screen and (max-width:1275px) {
  .landingContainer {
    flex-direction: column;
    align-items: center;
  }

  .mobileImgContainer,
  .mobileImg {
    align-items: left;
  }


  .landingContainer>* {
    text-align: center;
    margin: 0 0;
    padding: 0 0;
    border: 0 0;
    text-align: left;
  }

  .heroContentContainer {
    max-width: 900px;
    min-width: 350px;
  }


  .mobileImg {
    display: block;
    margin-top: 2em;
    max-width: 900px;
    min-width: 350px;
  }

  .heroImgContainer {
    display: none;
  }

  .landing,
  .sand,
  .meImg {
    display: none;
  }

  .heroContentContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .heroContentContainer>h1 {
    margin: 1.4em 1em 0 1em;
  }

  .heroContentContainer>h1:nth-child(2) {
    margin: 0 .5em
  }

  .projectsResume {
    margin-top: 1.2em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .mobileImgContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 620px;
  }

  .mobileImg {
    position: relative;
  }

  .mobileImg:nth-child(2) {
    position: absolute;
    box-shadow: none;
    left: 0;
  }

  .mobileImg:nth-child(1) {
    box-shadow: none;
    position: absolute;

  }

  .mobileImg:nth-child(3) {
    overflow-x: hidden;
    box-shadow: none;
  }

  @keyframes sandSlideInMobile {
    0% {
      clip-path: inset(0 100% 0 0);
    }

    20% {
      clip-path: inset(0 100% 0 0);
    }

    100% {
      clip-path: inset(0 0 0 0);
    }
  }

  .animateMeClass {
    animation: slideIn 1500ms ease-out 00ms 1 normal forwards;
    -webkit-animation: slideIn 1500ms ease-out 00ms 1 normal forwards;
    -moz-animation: slideIn 1500ms ease-out 00ms 1 normal forwards;
  }

  .animateSandClass {
    animation: sandSlideInMobile 3460ms ease-out 0s 1 normal forwards;
    -webkit-animation: sandSlideInMobile 3460ms ease-out 0s 1 normal forwards;
    -moz-animation: sandSlideInMobile 3460ms ease-out 0s 1 normal forwards;
  }
}

.btnLanding {
  margin-bottom: 0;
}